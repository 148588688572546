import get from 'lodash/get'

export function artDirecting(image, mobileImage) {
  return mobileImage
    ? [
        mobileImage.fluid,
        {
          ...image.fluid,
          media: '(min-width: 1024px)',
        },
      ]
    : get(image, 'fluid')
}
